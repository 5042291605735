import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Games from "../Games.json";

const shuffleArray = (array) => {
  return array.sort(() => Math.random() - 0.5);
};

export default function Home() {
  const [isLoad, setIsLoad] = useState(true);
  const [randomGames, setRandomGames] = useState([]);

  useEffect(() => {
    const shuffledGames = shuffleArray([...Games]).slice(0, 20);
    setRandomGames(shuffledGames);
  }, []);

  setTimeout(() => {
    setIsLoad(false);
  }, 500);

  return isLoad ? (
    <section className="flex items-center justify-center p-8">
      <div className="containerLoader">
        <span></span>
      </div>
    </section>
  ) : (
    <section className="flex items-center justify-center p-8">
      <div className="grid md:grid-cols-3 grid-cols-1 gap-6 w-full">
        {randomGames.map((data, index) => (
          <div
            className="rounded-lg overflow-hidden shadow-lg shadow-gray-500 transform transition duration-300 hover:scale-105 "
            key={index}
          >
            <img className="w-full" src={data.logo} alt={data.title} />
            <div className="flex items-center justify-between px-3 py-2">
              <span className="text-xl font-bold text-gray-900 ">
                {data.title}
              </span>
              <Link
                to={`/single?slug=${data.slug}`}
                className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-bold rounded-lg text-xl px-5 py-2 text-center me-2 mb-2"
              >
                Play
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
